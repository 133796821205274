import React from 'react'

import ButtonBar from 'components/ButtonBar'

import { chunkedAlphabet } from '../utils'

const Glossary = ({ relativePath = false }) => {
  return (
    <ButtonBar
      buttons={chunkedAlphabet.map((group, index) => ({
        id: group,
        children: group,
        ...(relativePath
          ? {
              href: `#${group[0]}`,
            }
          : {
              to: `/glossar#${group[0]}`,
            }),
      }))}
      fullWidth
      minWidth={540}
    />
  )
}

export default Glossary
