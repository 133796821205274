import React from 'react'
import { Link as RouterLink } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'

import { Section, Container, Heading, Text, Link, Box } from 'components'
import Layout from 'components/Layout'

import Navigation from './components/Navigation'
import { alphabet } from './utils'

const Glossary = () => {
  const {
    glossary: { nodes: glossary },
  } = useStaticQuery(graphql`
    query {
      glossary: allContentfulGlossary(
        filter: { node_locale: { eq: "de" } }
        sort: { fields: term, order: ASC }
      ) {
        nodes {
          id
          term
          slug
        }
      }
    }
  `)

  const glossar = alphabet.map((key) => {
    const terms = glossary.filter(({ term = '' }) =>
      term.toUpperCase().startsWith(key)
    )
    return {
      title: key,
      terms: terms,
    }
  })

  return (
    <Layout
      seo={{
        title: 'Bambus Glossar',
        description:
          'Wörterverzeichnis der wichtigsten Begriffe rund um Immobilie, Eigentum, Beleihungen und Teilverkauf.',
      }}
    >
      <Section spacing="small">
        <Container>
          <Box sx={{ display: 'flex' }}>
            <Text sx={{ fontSize: 0, ml: 1 }}>Glossar</Text>
            <Text variant="muted" sx={{ ml: 1 }}>
              / Alle
            </Text>
          </Box>

          <Box mt={3} />

          <Navigation relativePath />

          <Heading.H2 as="h1" sx={{ pt: 5, pb: 6 }}>
            Glossar
          </Heading.H2>

          {glossar.map(({ title, terms }) => (
            <React.Fragment key={title}>
              <Heading.H2
                id={title}
                sx={{
                  fontSize: [7, 7],
                  fontWeight: 'body',
                  color: 'colorBlue25',
                  pb: 2,
                  borderBottom: '1px solid',
                  borderColor: 'colorBlue10',
                }}
              >
                {title}
              </Heading.H2>
              <Box
                sx={{
                  pt: 4,
                  pb: 8,
                  columnCount: terms.length > 1 ? [1, 2, 2, 3] : 1,
                  columnFill: 'balance',
                }}
              >
                {terms.map(({ slug, term }) => (
                  <Link
                    key={slug}
                    id={slug}
                    as={RouterLink}
                    to={`/glossar/${slug}`}
                    sx={{
                      display: 'block',
                      py: 3,
                      fontSize: [2, 3],
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      breakInside: 'avoid',
                    }}
                  >
                    {term}
                  </Link>
                ))}
              </Box>
            </React.Fragment>
          ))}
        </Container>
      </Section>
    </Layout>
  )
}

export default Glossary
